import React from 'react'

import { useSpotify } from '../utils/spotify'

export default function Spotify() {
  const { connectCallback } = useSpotify()

  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const code = query.get('code')
    if (!code) return // TODO: show error
    connectCallback(code)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // TODO: replace with loading page
  return null
}
